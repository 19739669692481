import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { SignupForm } from '../../components/Forms';
export const _frontmatter = {
  "slug": "/challenge-5/",
  "date": "2020-07-03T00:00:00.000Z",
  "title": "MDX my new bff",
  "blurb": "My challenges and victories whilst attempting challenge #5",
  "tags": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Hello people who've made it thus far,`}</p>
    <p>{`I have accomplished this week's task–behold! A React component embeded within MD!`}</p>
    <SignupForm mdxType="SignupForm" />
    <p>{`Our `}<Link to={"/challenge-4"} mdxType="Link">{`signup form from challenge 4`}</Link>{`! In hindsight it was easy as pie. Only took a few days extra.`}</p>
    <h2>{`Moving to MDX`}</h2>
    <p>{`Anyone familiar with React knows that JSX is the bread and butter to the library. MDX is the love child of JSX and MD 🤱`}</p>
    <p>{`In a nutshell MDX allows you to utilise JS, HTML, CSS (with `}<span className="code">{`styles={{ style }}`}</span>{`), and MD. However, I still prefer to keep my CSS out of the mix.`}</p>
    <p>{`So you probably already know how this works when undergoing a challenge: read some `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/mdx/"
      }}>{`related docs`}</a>{`, follow along and jiggle things around until it works. Very, `}<em parentName="p">{`very`}</em>{` methodically, of course.`}</p>
    <p>{`I mainly followed along to the doc regarding the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/mdx/migrate-remark-to-mdx/"
      }}>{`migration from Remark to MDX`}</a>{`. I didn't run into any notable issues (besides me misreading) and the docs were relatively simple to follow which is always nice but quite boring.`}</p>
    <p>{`Anyhoo, with the migration we're able to use any file with the extension .md or .mdx which means any old posts that use .md still works.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`All in all, completing this task was relatively simple and easy to fulfill. I shall be using MDX from now on :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      